
import { defineComponent } from "vue";

import AppLocales from "../AppLocales/AppLocales.vue";
import AppUser from "../AppUser/AppUser.vue";
import LogoutButton from "../../buttons/LogoutButton/LogoutButton.vue";
import AppLogo from "../AppLogo/AppLogo.vue";
import AppHamburgetButton from "../AppSidebar/AppHamburgetButton.vue";
import { NotificationsButton } from "@/modules/notifications";

export default defineComponent({
  emits: ["mobileButtonClick"],
  components: {
    AppLocales,
    AppUser,
    LogoutButton,
    AppLogo,
    AppHamburgetButton,
    NotificationsButton,
  },

  data() {
    return {
      showHamburgerButton: false,
    };
  },

  mounted() {
    document.addEventListener("resize", this.onResize);
    this.onResize();
  },

  beforeUnmount() {
    document.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.showHamburgerButton =
        window.matchMedia("(max-width: 768px)").matches;
    },
  },
});
