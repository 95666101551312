
import { defineComponent } from "vue";
import VButton from "../BaseButton/BaseButton.vue";
import { AuthActions } from "@/store/modules/auth";
import { useToast } from "vue-toastification";
export default defineComponent({
  components: {
    VButton,
  },

  setup() {
    const toast = useToast();

    return { toast };
  },

  methods: {
    logout() {
      this.$store.dispatch(AuthActions.LOGOUT, { toast: this.toast });
    },
  },
});
