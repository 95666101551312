import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_field = _resolveComponent("select-field")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_openBlock(), _createBlock(_component_form_field, {
    label: "ТС",
    error: _ctx.error
  }, {
    default: _withCtx(() => [
      _createVNode(_component_select_field, {
        options: _ctx.routes,
        name: "syncTypes",
        "input-placeholder": "Оберіть ТС",
        "search-placeholder": "Введіть ТС",
        "has-search": true,
        "model-value": _ctx.value,
        "onUpdate:modelValue": _ctx.select
      }, null, 8, ["options", "model-value", "onUpdate:modelValue"])
    ]),
    _: 1
  }, 8, ["error"]))
}