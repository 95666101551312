
import VSpinner from "@/components/spinner/VSpinner.vue";
export default {
  components: {
    VSpinner,
  },
  props: {
    loading: Boolean,
  },
};
