
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["mobileButtonClick"],
  data() {
    return {
      active: false,
    };
  },

  watch: {
    active() {
      this.$emit("mobileButtonClick", this.active);
    },
  },

  methods: {
    toggle() {
      this.active = !this.active;
    },
  },
});
