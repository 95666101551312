
import { MenuIcons, getMenuIcons } from "@/api/menu/menu.api";
import { defineComponent } from "vue";

export default defineComponent({
  props: ["submenu", "index"],
  data(): { opened: boolean; menuIcons: MenuIcons | null } {
    return {
      opened: false,
      menuIcons: null,
    };
  },

  mounted() {
    const activeSubmenuIndex = JSON.parse(
      window.localStorage.getItem("activeSubmenu") || "-1"
    );

    this.menuIcons = getMenuIcons();
    this.opened = activeSubmenuIndex === this.index;
  },

  methods: {
    open() {
      if (this.opened) {
        window.localStorage.setItem("activeSubmenu", "-1");
      } else {
        window.localStorage.setItem("activeSubmenu", this.index);
      }
      this.opened = !this.opened;
    },
  },
});
