import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_ctx.opened)
    ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "context-menu",
        style: _normalizeStyle({ top: `${_ctx.y}px`, left: `${_ctx.x}px` }),
        ref: "contextMenu"
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 4)), [
        [_directive_click_outside, _ctx.close]
      ])
    : _createCommentVNode("", true)
}