
import { defineComponent } from "vue";
import { Rules } from "@/contants/rules";
import VButton from "@/components/buttons/BaseButton/BaseButton.vue";

export default defineComponent({
  components: {
    VButton,
  },
  setup() {
    return { Rules };
  },
});
