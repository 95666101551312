export enum ApiRoutes {
  LOGIN = "/UserAuth/index.php",
  LOGOUT = "/Exit/index.php",
  RESET_PASSWORD = "/PassChange/index.php",
  GET_AUTH_STATUS = "/AuthCheck/index.php",
  ACTIVATE_TERMINAL = "/Terminals/Activate/index.php",
  TERMINALS_LIST = "Terminals/List/index.php",
  TERMINALS_REFS = "Terminals/Ref/index.php",
  GET_TERMINAL_CONFIG = "Terminals/Config/Get/index.php",
  CREATE_OFFLINE_TERMINAL = "Terminals/Config/Set/index.php",
  TRANSACTIONS_LIST = "Payments/Transactions/index.php",
  PAYMENTS_REFUND = "Payments/Refund/index.php",
  EDIT_TERMINAL = "Terminals/Edit/index.php",
  SCAN_QR = "Terminals/Scan/index.php",
  SEND_TERMINAL_COMMAMD = "Terminals/AddCmd/index.php",
  ECOMM_TERMINAL_REF = "Terminals/Ecomm/Ref/index.php",

  UPLOAD_FISCAL_KEY = "Points/KeyData/index.php",
  ADD_POINT = "Points/Add/index.php",
  GET_POINTS = "Points/List/index.php",

  CREATE_ECOMM_TERMINAL = "/Terminals/Ecomm/Add/index.php",
  GET_ECOMM_TERMINALS = "/Terminals/Ecomm/List/index.php",

  GET_ECOMM_TRANSACTIONS = "/Payments/Transactions/Ecomm/index.php",
  GET_REF_ECOMM_TRANSACTIONS = "/Payments/Transactions/Ecomm/Ref/index.php",
}
