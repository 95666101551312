import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_loading = _resolveComponent("app-loading")!
  const _component_create_route_button = _resolveComponent("create-route-button")!
  const _component_routes_table = _resolveComponent("routes-table")!
  const _component_app_layout = _resolveComponent("app-layout")!

  return (_openBlock(), _createBlock(_component_app_layout, null, {
    appLoading: _withCtx(() => [
      _createVNode(_component_app_loading, { loading: _ctx.loading }, null, 8, ["loading"])
    ]),
    appTitle: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("Перегляд маршрутів")), 1)
    ]),
    appExtra: _withCtx(() => [
      _createVNode(_component_create_route_button)
    ]),
    appContent: _withCtx(() => [
      _createVNode(_component_routes_table, { data: _ctx.routes }, null, 8, ["data"])
    ]),
    _: 1
  }))
}