import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_button = _resolveComponent("v-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: 'createRoute' },
    class: "terminal-create-btn"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_button, { hasMaxWidth: false }, {
        text: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("Додати маршрут")), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}