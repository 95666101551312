import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-navbar" }
const _hoisted_2 = { class: "app-navbar__logo" }
const _hoisted_3 = { class: "app-navbar__tools" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_logo = _resolveComponent("app-logo")!
  const _component_app_locales = _resolveComponent("app-locales")!
  const _component_app_user = _resolveComponent("app-user")!
  const _component_notifications_button = _resolveComponent("notifications-button")!
  const _component_logout_button = _resolveComponent("logout-button")!
  const _component_app_hamburget_button = _resolveComponent("app-hamburget-button")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_logo)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_app_locales),
      _createVNode(_component_app_user),
      _createVNode(_component_notifications_button),
      _createVNode(_component_logout_button),
      (_ctx.showHamburgerButton)
        ? (_openBlock(), _createBlock(_component_app_hamburget_button, {
            key: 0,
            onMobileButtonClick: _cache[0] || (_cache[0] = (value) => _ctx.$emit('mobileButtonClick', value))
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}