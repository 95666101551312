<template>
  <div class="popover" v-click-outside="close" @click.stop ref="popover">
    <div class="popover-trigger" ref="trigger" @click="toggle">
      <!-- TODO: Move this into slot -->

      <div class="notifications-button">
        <div class="notifications-indicator"></div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#ff7a2e"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="notification-icon"
        >
          <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
          <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
        </svg>
      </div>
    </div>
    <div class="popover-tooltip" ref="tooltip" :data-show="opened">
      <div class="popover-tooltip__actions" v-if="opened">
        <div class="notifications-container">
          <h2 class="notifications-title">Повідомлення</h2>
          <div class="notifications-list">
            <p class="notification-text">Текст повідомлення</p>
            <p class="notification-text">Текст повідомлення</p>
            <p class="notification-text">Текст повідомлення</p>
            <p class="notification-text">Текст повідомлення</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { createPopper } from "@popperjs/core";

export default defineComponent({
  data() {
    return {
      opened: false,
    };
  },

  mounted() {
    const trigger = this.$refs.trigger;
    const tooltip = this.$refs.tooltip;

    this.popover = createPopper(trigger, tooltip, {
      placement: "bottom",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [-10, 10],
          },
        },
      ],
    });
  },

  methods: {
    toggle() {
      this.opened = !this.opened;

      this.popover?.update();

      this.popover?.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: "eventListeners", enabled: this.opened },
        ],
      }));
    },
    close() {
      const tooltip = this.$refs.tooltip;
      tooltip.style.opacity = "1";
      this.opened = false;
    },

    hide() {
      const tooltip = this.$refs.tooltip;
      tooltip.style.opacity = "0";
    },
  },
});
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
