
import { defineComponent } from "vue";
import {
  getMenu,
  getMenuIcons,
  MenuIcons,
  MenuView,
} from "@/api/menu/menu.api";
import AppSubmenu from "./AppSubmenu.vue";

export default defineComponent({
  components: {
    AppSubmenu,
  },
  data(): {
    activeIndex: number;
    menu: MenuView[];
    menuIcons: MenuIcons | null;
  } {
    return {
      activeIndex: -1,
      menu: [] as MenuView[],
      menuIcons: null,
    };
  },

  mounted() {
    this.menu = getMenu();
    this.menuIcons = getMenuIcons();
    this.activeIndex = JSON.parse(
      window.localStorage.getItem("activeMenu") || "-1"
    );
  },

  watch: {
    activeIndex() {
      if (this.activeIndex < 0) {
        window.localStorage.setItem("activeSubmenu", "-1");
      }
      window.localStorage.setItem(
        "activeMenu",
        JSON.stringify(this.activeIndex)
      );
    },
  },

  methods: {
    open(index: number) {
      if (this.activeIndex === index) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = index;
      }
    },
  },
});
