<template lang="">
  <div class="app-logo">
    <img src="@/assets/logo.svg" alt="Just Sell" class="app-logo__img" />
    <div class="app-logo__text">
      {{ $t("Прийом платежів на будь-якому пристрої") }}
    </div>
  </div>
</template>
<script>
export default {};
</script>
