import { createI18n } from "vue-i18n";

import ua from "../../locales/ua.json";
import en from "../../locales/en.json";

export const i18n = createI18n({
  locale: window.localStorage.getItem("locale") || "ua",
  fallbackLocale: "ua",
  messages: {
    ua,
    en,
  },
});
