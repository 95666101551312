
import { defineComponent } from "vue";
import SelectField from "../fields/SelectField/SelectField.vue";

export default defineComponent({
  props: ["total"],
  components: {
    SelectField,
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      pages: 0,
    };
  },

  setup() {
    const pageLimits = [10, 20];
    const pageLimitsOptions = pageLimits.map((limit) => ({
      label: limit,
      value: limit,
    }));

    return { pageLimitsOptions };
  },

  mounted() {
    const page = parseInt(this.$route.query.page as string) + 1 || 1;
    const perPage = parseInt(this.$route.query.perPage as string) || 10;

    this.setPage(page);
    this.setPerPage(perPage);

    this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        perPage: this.perPage,
        page: this.page - 1,
      },
    });
  },

  computed: {
    canNext(): boolean {
      return this.page < this.pages;
    },
    canPrev(): boolean {
      return this.page > 1;
    },
    isMobile(): boolean {
      return window.matchMedia("(max-width: 768px)").matches;
    },
  },

  watch: {
    total: {
      handler(value: number) {
        this.setPages(value);
      },
      immediate: true,
    },
    perPage: {
      handler(value: number) {
        const query = this.$route.query;
        this.$router.replace({
          path: this.$route.path,
          query: { ...query, perPage: value },
        });
        this.setPages(this.total);
        this.setPage(1);
      },
      immediate: true,
    },
    page: {
      handler(value: number) {
        const query = this.$route.query;
        this.$router.replace({
          path: this.$route.path,
          query: { ...query, page: value - 1, perPage: this.perPage },
        });
      },
    },

    "$route.query.page": {
      handler(value) {
        this.setPage(parseInt(value) + 1 || 1);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    next() {
      if (!this.canNext) {
        return;
      }

      this.page = this.page + 1;
    },
    prev() {
      if (this.canPrev) {
        return;
      }

      this.page = this.page - 1;
    },
    setPage(value: number) {
      this.page = value;
    },
    setPerPage(value: number) {
      this.perPage = value;
    },
    setPages(total: number) {
      this.pages = Math.ceil(total / this.perPage);
    },
  },
});
